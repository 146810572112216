import { render, staticRenderFns } from "./OrderCart.vue?vue&type=template&id=fe10d74e&scoped=true&"
import script from "./OrderCart.vue?vue&type=script&lang=js&"
export * from "./OrderCart.vue?vue&type=script&lang=js&"
import style0 from "./OrderCart.vue?vue&type=style&index=0&id=fe10d74e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe10d74e",
  null
  
)

export default component.exports