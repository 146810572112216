<template>
  <v-container fluid class="pt-0">
    <!-- Summary section -->

    <v-snackbar
      v-model="snackbar.active"
      bottom
      color="yellow darken-2"
      :timeout="2000"
    >
      <v-icon left light small class="mr-1">check_circle</v-icon>
      <b class="black--text">{{ snackbar.msg }}</b>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.active = false">
          ปิด
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFaild.active"
      bottom
      color="error"
      :timeout="2000"
    >
      <v-icon left dark small class="mr-1">cancel</v-icon>
      <b>{{ snackbarFaild.msg }}</b>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbarFaild.active = false"
        >
          ปิด
        </v-btn>
      </template>
    </v-snackbar>

    <!-- bottom sheet component -->
    <OrderDetailSheet
      :id="orderIdSheet"
      @clear-id="orderIdSheet = ''"
    ></OrderDetailSheet>
    <!-- end bottom sheet component -->

    <v-row>
      <v-col lg="4" md="4" sm="12" cols="12">
        <OrderTotalCard
          v-if="isAdminLevel"
          title="TOTAL"
          avatar_bg="blue"
          avatar_icon="mdi-cart-outline"
          type="total"
          status="wait"
          ref="totalCard"
        />
      </v-col>

      <v-col lg="4" md="4" sm="12" cols="12">
        <OrderTotalCard
          v-if="isAdminLevel"
          title="COD จ่ายปลายทาง"
          avatar_bg="green"
          avatar_icon="cloud_queue"
          type="cod"
          status="wait"
          ref="codCard"
        />
      </v-col>

      <v-col lg="4" md="4" sm="12" cols="12">
        <OrderTotalCard
          v-if="isAdminLevel"
          title="โอนเงิน"
          avatar_bg="#dd4b39"
          avatar_icon="local_mall"
          type="tranfer"
          status="wait"
          ref="tranferCard"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          v-model="filterBranchId"
          :items="branches"
          label="สาขา"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="filterStatus"
          :items="itemsMenuFilterAction"
          label="สถานะ"
          item-text="title"
          item-value="actionType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="filterTransporter"
          :items="transporterMenuAction"
          label="บริษัทขนส่ง"
          item-text="title"
          item-value="actionType"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-btn outlined small class="mt-4 ml-0 mr-1" @click="doSearch"
          ><v-icon left>search</v-icon> ค้นหา</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="pb-2">
      <v-col
        >จำนวน
        <v-chip label color="blue-grey darken-3" dark class="pl-1 pr-1 mr-3" small
          ><b class="body-2">{{ totalItems }}</b></v-chip
        >
        <v-menu
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              small
              color="orange darken-4"
              dark
              v-bind="attrs"
              v-on="on"
            >
              KERRY 
              <v-icon small right>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-subheader class="pb-0 mb-0">ดูสถานะ</v-subheader>
            <v-list-item @click="quickmenu('kerry', orderstatusconstant.WAIT)">
              <v-list-item-title class="text-body-2">รอ</v-list-item-title>
            </v-list-item>
            <v-list-item @click="quickmenu('kerry', orderstatusconstant.KERRYEXPORTED)">
              <v-list-item-title class="text-body-2">KERRY-Exported</v-list-item-title>
            </v-list-item>
            <v-list-item @click="quickmenu('kerry', orderstatusconstant.KERRYPRINTED)">
              <v-list-item-title class="text-body-2">KERRY-Printed</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader>ดำเนินการ</v-subheader>
            <v-list-item @click="exportKERRY">
              <v-list-item-title class="text-body-2"><v-icon left size="18" color="red darken-3">mdi-file-excel-outline</v-icon> Export KERRY</v-list-item-title>
            </v-list-item>
            <v-list-item @click="printOrderKerry">
              <v-list-item-title class="text-body-2"><v-icon left size="18" color="red darken-3">print</v-icon> Print KERRY</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              small
              color="grey darken-3"
              v-bind="attrs"
              v-on="on"
            >
              <b class="yellow--text text--darken-1">Flash</b> 
              <v-icon small right color="yellow darken-1">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-subheader class="pb-0 mb-0">ดูสถานะ</v-subheader>
            <v-list-item @click="quickmenu('flash',orderstatusconstant.WAIT)">
              <v-list-item-title class="text-body-2">รอ</v-list-item-title>
            </v-list-item>
            <v-list-item @click="quickmenu('flash',orderstatusconstant.KAEXPORTED)">
              <v-list-item-title class="text-body-2">KA-Exported</v-list-item-title>
            </v-list-item>
            <v-list-item @click="quickmenu('flash',orderstatusconstant.BOOKING)">
              <v-list-item-title class="text-body-2">KA-Booking</v-list-item-title>
            </v-list-item>
            <v-list-item @click="quickmenu('flash',orderstatusconstant.KAPRINTED)">
              <v-list-item-title class="text-body-2">KA-PRINTED</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader class="pb-0 mb-0">ดำเนินการ</v-subheader>
            <v-list-item @click="exportKAFlashPrinter">
              <v-list-item-title><v-icon left size="18" color="yellow darken-3">mdi-file-excel-outline</v-icon> Export FlashPrinter</v-list-item-title>
            </v-list-item>
            <v-list-item @click="printOrderKA">
              <v-list-item-title><v-icon left size="18" color="yellow darken-3">print</v-icon> Print Flash</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn @click="resetSearch" color="grey darken-4" outlined small>ดูทั้งหมด</v-btn>
      </v-col>
    </v-row>

    <!-- Table section -->
    <v-card>
      <v-data-table
        mobile-breakpoint="200"
        v-model="selected"
        show-select
        item-key="id"
        :headers="headers"
        :items="mDataArray"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
        @item-selected="mySelected"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="yellow lighten-4" light height="55">
            <v-badge size="18" color="yellow accent-4">
              <template v-slot:badge>
                <span class="black--text">{{ selected.length }}</span>
              </template>
              เลือก
            </v-badge>
            <v-divider class="mx-4 mr-1" inset vertical=""></v-divider>
            <v-btn @click="handlePastetxt" x-small class="mr-2" text><v-icon size="20">mdi-content-paste</v-icon></v-btn>
            <v-text-field
              v-model.trim="search"
              name="search"
              label="พิมพ์ ชื่อ | เบอร์โทร"
              id="search"
              single-line
              hide-details
              autocomplete="off"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-menu subheader>
              <template v-slot:activator="{ on }">
                <v-btn class="mb-1" icon light v-on="on">
                  <v-icon class="ml-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>เมนู</v-subheader>
                <v-list-item @click="printOrder">
                  <v-list-item-icon class="mr-3"
                    ><v-icon size="19">print</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >Print
                      <v-chip color="yellow" small
                        >ปกติ</v-chip
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader>ขนส่ง FLASH</v-subheader>
                <v-list-item @click="exportKA">
                  <v-list-item-icon class="mr-3"
                    ><v-icon size="19" color="grey lighten-1"
                      >flash_on</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >Export KA</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="exportKAFlashPrinter">
                  <v-list-item-icon class="mr-3"
                    ><v-icon size="19" color="yellow darken-1"
                      >flash_on</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >Export FlashPrinter</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="printOrderKA">
                  <v-list-item-icon class="mr-3"
                    ><v-icon size="19">print</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >Print
                      <v-chip color="blue" dark small
                        >KA</v-chip
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader>ขนส่ง KERRY</v-subheader>
                <v-list-item @click="exportKERRY">
                  <v-list-item-icon class="mr-3"
                    ><v-icon size="19" color="orange darken-1"
                      >flash_on</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >Export KERRY</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="printOrderKerry">
                  <v-list-item-icon class="mr-3"
                    ><v-icon size="19">print</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >Print
                      <v-chip color="orange darken-2" dark small
                        >KERRY</v-chip
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader>เปลี่ยน สถานะ</v-subheader>
                <v-list-item
                  v-for="(item, index) in itemsMenuAction"
                  :key="index"
                  @click="doActionMenu(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="body-2">{{
                      item.title
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader>Scan Barcode</v-subheader>
                <v-list-item @click="doScan">
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >เพิ่มไปรายการ Scan</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected }">
          <tr :key="item.id">
            <td class="text-center">
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td><Transporter :id="item.transporter" />{{ item.id }}</td>
            <td class="pl-1 pr-1">
              <OrderStatus :status="item.status"></OrderStatus>
              <span
                class="caption grey--text text--darken-3"
                v-if="item.tracking"
                >{{ item.tracking }}</span
              >
            </td>
            <td class="caption">{{ item.branch }}</td>
            <td class="pt-2 pb-2">
              <v-chip
                label
                :dark="true"
                outlined
                color="blue darken-3"
                small
                v-if="item.tag"
              >
                {{ item.tag }}
              </v-chip>
              {{ item.name }}
              <div class="grey--text text--darken-3 caption my-td-width2">
                <v-icon color="blue lighten-2" size="15">mdi-phone</v-icon>
                {{ item.tel1 }}, {{ item.tel2 }}
              </div>
              <div v-if="item.remark_admin">
                <v-icon size="15" color="orange darken-2">mdi-alert</v-icon>
                <span
                  class="caption grey--text text--darken-3 ml-1 yellow lighten-2"
                  >{{ item.remark_admin }}</span
                >
              </div>
              <div v-if="item.remark_product">
                <v-icon size="15" color="pink darken-2">mdi-alert</v-icon>
                <span
                  class="caption grey--text text--darken-4 ml-1 pink lighten-5"
                  >{{ item.remark_product }}</span
                >
              </div>
              
              <div v-if="item.tags">
                <v-chip
                  x-small
                  v-for="(tag, tindex) in item.tags.item"
                  :key="tindex"
                  class="mr-1"
                  >{{ tag.name }}</v-chip
                >
              </div>
            </td>
            <td>
              <v-chip
                color="green darken-3"
                v-if="item.is_cod == paymentMethods[1].id"
                class="ma-1 pa-1"
                small
                dark
                outlined
                label
              >
                <b>COD {{ item.amount | currency("", 0) }}</b>
              </v-chip>
              <v-chip
                color="blue darken-3"
                class="ma-1 pa-1"
                small
                dark
                outlined
                label
                v-else
              >
                PAID {{ item.amount | currency("", 0) }}
              </v-chip>
            </td>
            <!-- <td>
              <v-select
                class="my-select-style"
                v-model="item.is_cod"
                :items="paymentMethods"
                :rules="[v => !!v || 'กรุณาเลือก']"
                label="ประเภทชำระ"
                item-text="name"
                item-value="id"
                item-color="blue"
                @change="changePaymentMethod(item)"
              ></v-select>
            </td> -->

            <td>
              <v-select
                class="my-select-style2"
                v-model="item.transporter"
                :items="transporterSelect"
                :rules="[v => !!v || 'กรุณาเลือก']"
                label="ขนส่ง"
                item-text="name"
                item-value="id"
                @change="changeTransporter(item)"
              ></v-select>
            </td>
            <td>
              <div class="caption my-td-width">
                <v-icon color="orange" size="14">mdi-update</v-icon>
                {{ item.createdAt | moment("DD-MM-YYYY HH:mm") }}
              </div>
              <div v-if="item.lastedOrder" class="font-weight-bold caption">
                <v-icon color="red" small>sync</v-icon> Id:
                <v-icon size="14" @click="orderIdSheet = item.lastedOrderId"
                  >airplay</v-icon
                >
                {{ item.lastedOrderId }}
                <span class="font-weight-medium red--text"
                  >({{ item.lastedOrder | moment("from") }})</span
                >
              </div>
            </td>
            <td>
              <v-icon size="18" class="mr-1" @click="orderIdSheet = item.id"
                >airplay</v-icon
              >
              <v-icon size="18" class="mr-2" @click="editItem(item)"
                >search</v-icon
              >
              <v-icon size="18" class="mr-2" @click="deleteItem(item)"
                >delete</v-icon
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-row class="subtitle-2 mt-1">
      <v-col>
        <b>หมายเหตุ</b><br />
        <ul>
          <li>
            PRINT <v-chip color="yellow" small>ปกติ</v-chip> : ออเดอร์ ที่จะ
            <b>ปริ้น</b> ได้ต้องมีสถานะเป็น <b>รอ</b> เท่านั้น
            ปริ้นออเดอร์แปะหน้ากล่องทั่วๆไป
          </li>
          <li>
            PRINT <v-chip color="blue" dark small>KA</v-chip> : ออเดอร์ ที่จะ
            <b>ปริ้น</b> ได้ต้องมีสถานะเป็น <b>kA-Booking</b> เท่านั้น
            สำปรับปริ้นออเดอร์ที่บุ๊คแล้ว
          </li>
        </ul>
        <b>การ Export file เพื่อบุ๊คกิ้งต้องเลือกอย่างใดอย่างหนึ่ง</b><br />
        <ul>
          <li>
            Export Flash - web สำหรับบุ๊คกิ้งทางเว็บไซต์แฟลช: ออเดอร์
            ที่จะส่งออกไฟล์เป็น <b>Export Flash</b> ได้ต้องมีสถานะเป็น
            <b>PRINTED</b> เท่านั้น
          </li>
          <li>
            Export KA สำหรับบุ๊คกิ้งแฟลชระบบ KA: ออเดอร์ ที่จะส่งออกไฟล์เป็น
            <b>Export KA</b> ได้ต้องมีสถานะเป็น <b>รอ</b> เท่านั้น เมื่อ Export
            แล้วจะเปลี่ยนสถานะเป็น <b>KA-Exported</b> อัตโนมัติ
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDeleteDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text class="body">
          ต้องการลบรายการนี้ใช่หรือไม่?
          <h3>{{ selectedItem }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="confirmDelete">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmChangeStatusDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">เปลี่ยนสถานะ</v-card-title>
        <v-card-text class="body">
          ต้องการเปลี่ยนสถานะเป็น
          <h3>
            <span class="blue--text">{{ selectedStatusAction.title }}</span>
            ใช่หรือไม่?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmChangeStatusDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="doChangeStatus">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogloading" hide-overlay persistent width="300">
      <v-card color="blue lighten-1" dark>
        <v-card-text>
          รอสักครู่..
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api, {
  ORDERSTATUS,
  PAYMENTMETHOD,
  ORDERID_PREFIX,
  SCANSTATUS,
  TRANSPORTER
} from "@/services/api"
import OrderDetailSheet from "@/components/OrderDetailSheet"
import OrderTotalCard from "@/components/cards/OrderTotalCard"
import Transporter from "@/components/helpers/Transporter"
import OrderStatus from "@/components/helpers/OrderStatus"
import Exceljs from "exceljs"
import { saveAs } from "file-saver"
import _ from "lodash"
import { userLevel } from "@/components/mixins/userLevel"

export default {
  name: "OrderCart",
  props: ["hasOrder"],
  mixins: [userLevel],
  data() {
    return {
      filterStatus: "",
      branches: [],
      filterBranchId: "",
      filterTransporter: "",
      orderIdSheet: "",
      isadmin: false,
      dialogloading: false,
      itemsMenuAction: [
        { title: "รอ", actionType: ORDERSTATUS.WAIT },
        { title: "รอจัดการ", actionType: ORDERSTATUS.PENDING },
        { title: "PRINTED", actionType: ORDERSTATUS.PRINTED },
        { title: "KA-Exported", actionType: ORDERSTATUS.KAEXPORTED },
        { title: "KA-Booking", actionType: ORDERSTATUS.BOOKING },
        { title: "KA-PRINTED", actionType: ORDERSTATUS.KAPRINTED },
        { title: "KERRY-Exported", actionType: ORDERSTATUS.KERRYEXPORTED },
        { title: "KERRY-Printed", actionType: ORDERSTATUS.KERRYPRINTED },
        { title: "KERRY-Booking", actionType: ORDERSTATUS.KERRYBOOKING },
        { title: "สำเร็จ", actionType: ORDERSTATUS.COMPLETED },
        { title: "ยกเลิก", actionType: ORDERSTATUS.CANCELLED },
        { title: "Pre-Order สั่งจอง", actionType: ORDERSTATUS.PREORDER }
      ],
      itemsMenuFilterAction: [
        { title: "รอ", actionType: ORDERSTATUS.WAIT },
        { title: "PRINTED", actionType: ORDERSTATUS.PRINTED },
        { title: "KA-Exported", actionType: ORDERSTATUS.KAEXPORTED },
        { title: "KA-Booking", actionType: ORDERSTATUS.BOOKING },
        { title: "KA-PRINTED", actionType: ORDERSTATUS.KAPRINTED },
        { title: "KERRY-Exported", actionType: ORDERSTATUS.KERRYEXPORTED },
        { title: "KERRY-Printed", actionType: ORDERSTATUS.KERRYPRINTED },
        { title: "KERRY-Booking", actionType: ORDERSTATUS.KERRYBOOKING }
      ],
      transporterMenuAction: [
        { title: "Kerry Express", actionType: TRANSPORTER.KERRYEXPRESS },
        { title: "Flash Express", actionType: TRANSPORTER.FLASHEXPRESS }
      ],
      selected: [],
      snackbar: {
        active: false,
        msg: ""
      },
      snackbarFaild: {
        active: false,
        msg: ""
      },
      paymentMethods: [
        { id: PAYMENTMETHOD.TRANFER, name: "โอนเงิน" },
        { id: PAYMENTMETHOD.COD, name: "COD จ่ายปลายทาง" }
      ],
      transporterSelect: [
        { id: TRANSPORTER.KERRYEXPRESS, name: "KERRY" },
        { id: TRANSPORTER.FLASHEXPRESS, name: "Flash" }
      ],
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
          width: 60
        },
        { text: "Status", value: "status", width: 90 },
        { text: "Branch", value: "branch" },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        // { text: "Method", value: "method" },
        { text: "Status", value: "status" },
        { text: "ใหม่ / สั่งล่าสุด", value: "date" },
        { text: "Action", value: "action" }
      ],
      search: "",
      selectedItemId: "",
      confirmDeleteDlg: false, // false เท่ากับไม่ให้แสดง
      selectedItem: "",
      dataTableLoading: false,
      confirmChangeStatusDlg: false,
      selectedStatusAction: "",
      totalItems: 0,
      options: {}, // for dataTable
      orderstatusconstant: ORDERSTATUS
    }
  },
  components: {
    // register ตัว components ที่เราทำการ import เข้ามาให้สามารถใช้งานได้
    OrderTotalCard,
    OrderStatus,
    OrderDetailSheet,
    Transporter
  },
  mounted() {
    console.log("this user components is running")
    //this.loadData();
    this.getBranches()
  },
  methods: {
    async handlePastetxt() {
      try {
        const data = await navigator.clipboard.readText();
        this.search = data.trim().substring(0,40)
      } catch (e) {
        console.log('empty data')
      }
    },
    async quickmenu(sender, status) {
      this.filterStatus = status
      this.filterTransporter = TRANSPORTER.KERRYEXPRESS
      if (sender == 'flash') {
        this.filterTransporter = TRANSPORTER.FLASHEXPRESS
      }

      this.filterBranchId = ''
      this.search = ''
      this.doSearch()
    },
    async getBranches() {
      let result = await api.getBranchActive()
      this.branches = result.data
      result = null
    },
    printOrder() {
      if (this.selected.length > 0) {
        this.$store.dispatch({
          type: "selectItems",
          items: this.selected
        })
        this.$store.dispatch({
          type: "hideMenu",
          isHide: false
        })
        this.$router.push(
          `/printorder/filter/wait/timestamp/` + Math.floor(Date.now() / 1000)
        )
      } else {
        console.log("no value")
      }
    },
    printOrderKA() {
      if (this.selected.length > 0) {
        this.$store.dispatch({
          type: "selectItems",
          items: this.selected
        })
        this.$store.dispatch({
          type: "hideMenu",
          isHide: false
        })
        this.$router.push(
          `/print-order-tracking/timestamp/` + Math.floor(Date.now() / 1000)
        )
      } else {
        console.log("no value")
      }
    },
    printOrderKerry() {
      if (this.selected.length > 0) {
        this.$store.dispatch({
          type: "selectItems",
          items: this.selected
        })
        this.$store.dispatch({
          type: "hideMenu",
          isHide: false
        })
        this.$router.push(
          `/print-order-kerry-tracking/timestamp/` +
            Math.floor(Date.now() / 1000)
        )
      } else {
        console.log("no value")
      }
    },
    async exportKA() {
      // ka old version
      if (
        confirm(
          "คุณต้องการ Export KA ใช่หรือไม่?! หากใช่ข้อมูลจะอัพเดตสถานะเป็น Ka-exported อัตโนมัติ"
        )
      ) {
        let detectsporterror = false
        let itemsSelected = this.selected
        if (itemsSelected.length > 0) {
          let ids = _.map(itemsSelected, item => {
            if(item.transporter == TRANSPORTER.KERRYEXPRESS) {
              detectsporterror = true
            }
            return item.id
          })

          if (detectsporterror) {
            alert('เลือก Export บริษัทขนส่งผิด! กรุณาลองใหม่')
            return;
          }

          let formData = new FormData()
          formData.append("items", JSON.stringify(ids))

          this.dialogloading = true // show dialog loading
          let result = await api.getOrdersById(formData)
          if (result.data.result == "ok") {
            let orders = JSON.parse(result.data.message)

            let mData = _.map(orders, order => {
              if (order.status == ORDERSTATUS.WAIT) {
                let order_id = ORDERID_PREFIX + _.padStart(order.id, "5", "0")
                let addrinfo2 = order.address_info2
                let post_code = addrinfo2.substring(
                  addrinfo2.length - 5,
                  addrinfo2.length
                )
                let amount = ""
                if (order.is_cod == PAYMENTMETHOD.COD) {
                  if (order.amount > 0) {
                    amount = order.amount
                  }
                }

                //splitting district
                let address_split = order.address_info2.split(", ")
                let new_address = ""
                if (address_split[2].trim() == "กรุงเทพ") {
                  new_address = `แขวง ${address_split[0]} เขต ${address_split[1]} ${address_split[2]}`
                } else {
                  new_address = `ตำบล ${address_split[0]} อำเภอ ${address_split[1]} ${address_split[2]}`
                }
                // ka system old version
                return {
                  orderid: order.id,
                  Customer_order_number: order_id,
                  Consignee_name: order.name,
                  Address: order.address_info + " " + new_address,
                  Postal_code: parseInt(post_code),
                  Phone_number: order.tel1,
                  Phone_number2: "",
                  COD: amount,
                  Item_type: "",
                  Weight_kg: 1,
                  Length: 1,
                  Width: 1,
                  Height: 1,
                  Freight_insurance: "",
                  Value_insurance: "",
                  Declared_value: "",
                  Packaging_damage_insurance: "",
                  Product_type: "Standard",
                  remark1: "",
                  remark2: "",
                  remark3: ""
                }
              }
            })

            let workbook = new Exceljs.Workbook()
            workbook.creator = "pols"
            let worksheet = workbook.addWorksheet("My Order")

            // flash KA system old version
            worksheet.columns = [
              {
                header: "Customer_order_number",
                key: "Customer_order_number",
                width: 12
              },
              { header: "*Consignee_name", key: "Consignee_name", width: 30 },
              { header: "*Address", key: "Address", width: 70 },
              { header: "*Postal_code", key: "Postal_code", width: 10 },
              { header: "*Phone_number", key: "Phone_number", width: 15 },
              { header: "Phone_number2", key: "Phone_number2", width: 15 },
              { header: "COD", key: "COD", width: 10 },
              { header: "Item_type", key: "Item_type", width: 10 },
              { header: "*Weight_kg", key: "Weight_kg", width: 10 },
              { header: "Length", key: "Length", width: 10 },
              { header: "Width", key: "Width", width: 10 },
              { header: "Height", key: "Height", width: 10 },
              {
                header: "Freight_insurance",
                key: "Freight_insurance",
                width: 10
              },
              { header: "Value_insurance", key: "Value_insurance", width: 10 },
              { header: "Declared_value", key: "Declared_value", width: 10 },
              {
                header: "Packaging_damage_insurance",
                key: "Packaging_damage_insurance",
                width: 10
              },
              { header: "*Product_type", key: "Product_type", width: 10 },
              { header: "Remark1", key: "remark1", width: 10 },
              { header: "Remark2", key: "remark2", width: 10 },
              { header: "Remark3", key: "remark3", width: 10 }
            ]

            //version ของ sheet flash ได้ระบุ version ไว้ที่ column -> CU ก็คือลำดับที่ 99 ในตำแหน่งของ column excel ทำให้เราต้องระบุด้วยเช่นกัน
            // ให้เปิด sheet template ของ flash ที่ให้มาแล้วเลือ่นไปดู column CU จะพบเลข version ของ sheet
            // worksheet.getColumn(99).values = ['26/10/2022'];

            let ids_exported = []
            _.forEach(mData, r => {
              if (typeof r != "undefined") {
                ids_exported.push(r.orderid)
                worksheet.addRow(r)
              }
            })

            try {
              let dname = this.$moment().format("DDMMYYYY-HHmmss")
              let filename =
                "KA-ORDER-" +
                ids_exported.length +
                "-" +
                dname.toString() +
                ".xlsx"
              let databuffer = await workbook.xlsx.writeBuffer()
              saveAs(new Blob([databuffer]), filename)

              // update order status to ka-exported
              let formData2 = new FormData()
              formData2.append("items", JSON.stringify(ids_exported))
              formData2.append("status", ORDERSTATUS.KAEXPORTED)
              await api.updateBulkOrderStatus(formData2)
              this.dialogloading = false
              this.loadData()
            } catch (error) {
              console.log("export excel error:", error.message)
              this.dialogloading = false // hide dialog loading
            }
            orders = null
            mData = null
            workbook = null
            result = null
          } else {
            // no data
          }
          ids = null
        } else {
          alert("กรุณาเลือก order ที่ต้องการ export!")
        }
        itemsSelected = null
        this.selected = []
      }
    },
    async exportKAFlashPrinter() {
      //new version for flashprinter software
      if (
        confirm(
          "คุณต้องการ Export FlashPrinter ใช่หรือไม่?! หากใช่ข้อมูลจะอัพเดตสถานะเป็น Ka-exported อัตโนมัติ"
        )
      ) {

        let detectsporterror = false
        let itemsSelected = this.selected
        if (itemsSelected.length > 0) {
          let ids = _.map(itemsSelected, item => {
            if(item.transporter == TRANSPORTER.KERRYEXPRESS) {
              detectsporterror = true
            }
            return item.id
          })
          
          if (detectsporterror) {
            alert('เลือก Export บริษัทขนส่งผิด! กรุณาลองใหม่')
            return;
          }

          let formData = new FormData()
          formData.append("items", JSON.stringify(ids))

          this.dialogloading = true // show dialog loading
          let result = await api.getOrdersById(formData)
          if (result.data.result == "ok") {
            let orders = JSON.parse(result.data.message)

            let mData = _.map(orders, order => {
              if (order.status == ORDERSTATUS.WAIT) {
                let order_id = ORDERID_PREFIX + _.padStart(order.id, "5", "0")
                let addrinfo2 = order.address_info2
                let post_code = addrinfo2.substring(
                  addrinfo2.length - 5,
                  addrinfo2.length
                )

                let amount = ""
                if (order.is_cod == PAYMENTMETHOD.COD) {
                  if (order.amount > 0) {
                    amount = order.amount
                  }
                }

                //splitting district
                let address_split = order.address_info2.split(",")
                let new_address = ""
                if (address_split[2].trim() == "กรุงเทพ") {
                  new_address = `แขวง ${address_split[0]} เขต ${address_split[1]} ${address_split[2]}`
                } else {
                  new_address = `ตำบล ${address_split[0]} อำเภอ ${address_split[1]} ${address_split[2]}`
                }

                return {
                  orderid: order.id,
                  Customer_order_number: order_id,
                  Consignee_name: order.name,
                  Address: order.address_info + " " + new_address,
                  Postal_code: parseInt(post_code),
                  Phone_number: order.tel1,
                  Phone_number2: "",
                  Number_of_parcels: 1,
                  COD: amount,
                  Item_type:'อื่นๆ',
                  Weight_kg: 1,
                  Length: 1,
                  Width: 1,
                  Height: 1,
                  Declared_value: "",
                  Box_shield: "",
                  Document_return_service: "",
                  Product_type: "Standard",
                  Payment_method: "payment by sender",
                  Remark: ""
                }
              }
            })

            let workbook = new Exceljs.Workbook()
            workbook.creator = "pols"
            let worksheet = workbook.addWorksheet("My Order")

            worksheet.columns = [
              {
                header: `Customer_order_number
(เลขออเดอร์ของลูกค้า)`,
                key: "Customer_order_number",
                width: 12
              },
              { header: `*Consignee_name
(ชื่อผู้รับ)`, key: "Consignee_name", width: 30 },
              { header: `*Address
(ทิ่อยู่)`, key: "Address", width: 70 },
              { header: `*Postal_code
(รหัสไปรษณีย์)`, key: "Postal_code", width: 10 },
              { header: `*Phone_number
(เบอร์โทรศัพท์)`, key: "Phone_number", width: 15 },
              { header: `Phone_number2
(เบอร์โทรศัพท์)`, key: "Phone_number2", width: 15 },
              {
                header: `Number of parcels 
（จำนวนพัสดุ）`,
                key: "Number_of_parcels",
                width: 10
              },
              { header: `COD
(ยอดเรียกเก็บ)`, key: "COD", width: 10 },
              { header: `Item_type
(ประเภทสินค้า)`, key: "Item_type", width: 10 },
              { header: `*Weight_kg
(น้ำหนัก)`, key: "Weight_kg", width: 10 },
              { header: `Length
(ยาว)`, key: "Length", width: 10 },
              { header: `Width
(กว้าง)`, key: "Width", width: 10 },
              { header: `Height
(สูง)`, key: "Height", width: 10 },
              { header: `Declared_value
(มูลค่าสินค้าที่ระบุโดยลูกค้า)`, key: "Declared_value", width: 10 },
              { header: `Box_shield `, key: "Box_shield", width: 10 },
              {
                header: `Document return service
(บริการส่งคืนเอกสาร)`,
                key: "Document_return_service",
                width: 10
              },
              { header: `*Product_type         (ประเภทสินค้า）`, key: "Product_type", width: 10 },
              { header: `*Payment method
（วิธีชำระเงิน）`, key: "Payment_method", width: 10 },
              { header: `Remark
(หมายเหตุ)`, key: "remark", width: 10 }
            ]

            // worksheet.columns = [
            //   {
            //     header: "Customer_order_number",
            //     key: "Customer_order_number",
            //     width: 12
            //   },
            //   { header: "*Consignee_name", key: "Consignee_name", width: 30 },
            //   { header: "*Address", key: "Address", width: 70 },
            //   { header: "*Postal_code", key: "Postal_code", width: 10 },
            //   { header: "*Phone_number", key: "Phone_number", width: 15 },
            //   { header: "Phone_number2", key: "Phone_number2", width: 15 },
            //   {
            //     header: "Number_of_parcels",
            //     key: "Number_of_parcels",
            //     width: 10
            //   },
            //   { header: "COD", key: "COD", width: 10 },
            //   { header: "Item_type", key: "Item_type", width: 10 },
            //   { header: "*Weight_kg", key: "Weight_kg", width: 10 },
            //   { header: "Length", key: "Length", width: 10 },
            //   { header: "Width", key: "Width", width: 10 },
            //   { header: "Height", key: "Height", width: 10 },
            //   { header: "Happy_return", key: "Happy_return", width: 10 },
            //   { header: "Declared_value", key: "Declared_value", width: 10 },
            //   { header: "Box_shield", key: "Box_shield", width: 10 },
            //   {
            //     header: "Document_return_service",
            //     key: "Document_return_service",
            //     width: 10
            //   },
            //   { header: "*Product_type", key: "Product_type", width: 10 },
            //   { header: "Payment_method", key: "Payment_method", width: 10 },
            //   { header: "Remark", key: "remark", width: 10 }
            // ]

            //version ของ sheet flash ได้ระบุ version ไว้ที่ column -> CU ก็คือลำดับที่ 99 ในตำแหน่งของ column excel ทำให้เราต้องระบุด้วยเช่นกัน
            // ให้เปิด sheet template ของ flash ที่ให้มาแล้วเลือ่นไปดู column CU จะพบเลข version ของ sheet
            // worksheet.getColumn(99).values = ["09/11/2022"]

            let ids_exported = []
            _.forEach(mData, r => {
              if (typeof r != "undefined") {
                ids_exported.push(r.orderid)
                worksheet.addRow(r)
              }
            })

            try {
              let dname = this.$moment().format("DDMMYYYY-HHmmss")
              let filename =
                "KAFLASHPRINTER-ORDER-" +
                ids_exported.length +
                "-" +
                dname.toString() +
                ".xlsx"
              let databuffer = await workbook.xlsx.writeBuffer()
              saveAs(new Blob([databuffer]), filename)

              // update order status to ka-exported
              let formData2 = new FormData()
              formData2.append("items", JSON.stringify(ids_exported))
              formData2.append("status", ORDERSTATUS.KAEXPORTED)
              await api.updateBulkOrderStatus(formData2)
              this.dialogloading = false
              this.loadData()
            } catch (error) {
              console.log("export excel error:", error.message)
              this.dialogloading = false // hide dialog loading
            }
            orders = null
            mData = null
            workbook = null
            result = null
          } else {
            // no data
          }
          ids = null
        } else {
          alert("กรุณาเลือก order ที่ต้องการ export!")
        }
        itemsSelected = null
        this.selected = []
      }
    },
    async exportKERRY() {
      if (
        confirm(
          "KERRY: คุณต้องการ Export KERRY ใช่หรือไม่?! หากใช่ข้อมูลจะอัพเดตสถานะเป็น KERRY-exported อัตโนมัติ"
        )
      ) {
        let detectsporterror = false
        let itemsSelected = this.selected
        if (itemsSelected.length > 0) {
          let ids = _.map(itemsSelected, item => {
            if(item.transporter == TRANSPORTER.FLASHEXPRESS) {
              detectsporterror = true
            }
            return item.id
          })
          if (detectsporterror) {
            alert('เลือก Export บริษัทขนส่งผิด! กรุณาลองใหม่')
            return;
          }

          let formData = new FormData()
          formData.append("items", JSON.stringify(ids))

          this.dialogloading = true // show dialog loading
          let result = await api.getOrdersByIdForNewLawCOD(formData)  // new law cod
          if (result.data.result == "ok") {
            let orders = JSON.parse(result.data.message)

            let mData = _.map(orders, (orderinfo) => {
              let order = orderinfo.orderinfo
              let prditems = orderinfo.products

              if (order.status == ORDERSTATUS.WAIT) {
                let order_id = ORDERID_PREFIX + _.padStart(order.id, "5", "0")
                let addrinfo2 = order.address_info2
                let post_code = addrinfo2.substring(
                  addrinfo2.length - 5,
                  addrinfo2.length
                )
                let amount = ""
                if (order.is_cod == PAYMENTMETHOD.COD) {
                  if (order.amount > 0) {
                    amount = order.amount
                  }
                }

                //splitting district
                let address_split = order.address_info2.split(", ")
                let new_address = ""
                if (address_split[2].trim() == "กรุงเทพ") {
                  new_address = `แขวง ${address_split[0]} เขต ${address_split[1]} ${address_split[2]}`
                } else {
                  new_address = `ตำบล ${address_split[0]} อำเภอ ${address_split[1]} ${address_split[2]}`
                }

                let branch = _.find(this.branches, { id: order.ref_branch_id })

                let productname = '' // new law cod
                if (amount > 0) {
                    if (prditems.length) {
                        let subname = _.map(prditems, 'product_subname')
                        productname = subname.join('| ')
                    }

                    if (order.remark_product) {
                      if (productname) {
                        productname = productname + '| '
                      }
                      productname = productname + '' + order.remark_product
                    }
                }

                // // kerry version ล่าสุด
                // return {
                //   orderid: order.id,
                //   consignment_no: this.$options.filters.KERRYTrackingFormat(
                //     order.id
                //   ),
                //   customer_ref_no: order_id,
                //   sender_code: branch.tag, //ใช้รหัสให้ตรงกับ ระบบ kerry ถ้าไม่ตรงจะใช้ค่า default ของ kerry แทน
                //   recipient_code: "",
                //   recipient_name: order.name,
                //   address: order.address_info + " " + new_address,
                //   postcode: parseInt(post_code),
                //   mobile: order.tel1,
                //   contact_person: branch.name, //แนะนำใส่ชื่อแบรนด์สินค้า
                //   phone_no: "",
                //   email: "",
                //   declare_value: "",
                //   cod_amount: amount,
                //   product_category: "", // 6112024
                //   product_name: productname, // ระบุเมื่อมียอด cod
                //   specification_type: "", // 6112024
                //   specification_detail: "", // 6112024
                //   color: "", // 6112024
                //   qty: "", // 6112024
                //   unit: "", // 6112024
                //   declare_value_for_cod: amount, // * 6112024 กรอกยอด cod
                //   parcel_type: "",
                //   width_cm: "1",
                //   length_cm: "1",
                //   height_cm: "1",
                //   weight: "",
                //   total_box: "",
                //   sat_del: "",
                //   hcr: "",
                //   return_pod: "N",
                //   service_code: "ND",
                //   consignment_type: "",
                //   return_pod_remark: "",
                //   return_pod_amount: "",
                //   special_remark: "",
                //   extended_label: ""
                // }

                // kex version lasted
                return {
                  orderid: order.id,
                  consignment_no: this.$options.filters.KERRYTrackingFormat(
                    order.id
                  ),
                  customer_ref_no: order_id,
                  sender_code: branch.tag,
                  recipient_code: "",
                  recipient_name: order.name,
                  recipient_phone: `${order.tel1}`,
                  recipient_postcode: parseInt(post_code),
                  recipient_address: order.address_info + " " + new_address,
                  recipient_contact_person: branch.name,
                  recipient_contact_person_phone: "",
                  service_code: "ND",
                  total_weight: 1,
                  length: 1,
                  width: 1,
                  height: 1,
                  total_box: 1,
                  special_remark: "",
                  extended_label: "",
                  declare_value: amount,
                  consignment_type: "",
                  cod_amount: amount, // ยอด cod 
                  declare_value_for_cod: amount, // ยอด cod
                  product_category: "ความสวย ความงาม",
                  product_name: productname, // กรอกถ้าเป็น cod
                  product_color: "",
                  product_specification_type: "",
                  product_specification: "",
                  product_quantity_type: "",
                  product_quantity: "",
                  return_pod: "N",
                  return_pod_remark: "",
                  return_pod_amount: ""
                }


              }
            })

            let workbook = new Exceljs.Workbook()
            workbook.creator = "pols"
            let worksheet = workbook.addWorksheet("My Order")

            // kerry version
            // worksheet.columns = [
            //   { header: "Consignment No.", key: "consignment_no", width: 15 },
            //   { header: "Customer Ref No.", key: "customer_ref_no", width: 15 },
            //   { header: "Sender Code", key: "sender_code", width: 10 },
            //   { header: "Recipient Code", key: "recipient_code", width: 10 },
            //   { header: "Recipient Name", key: "recipient_name", width: 30 },
            //   { header: "Address", key: "address", width: 70 },
            //   { header: "Postcode", key: "postcode", width: 10 },
            //   { header: "Mobile", key: "mobile", width: 15 },
            //   { header: "Contact Person", key: "contact_person", width: 20 },
            //   { header: "Phone No.", key: "Phone No.", width: 10 },
            //   { header: "Email", key: "email", width: 10 },
            //   { header: "Declare Value for Extra care", key: "declare_value", width: 10 },
            //   { header: "COD Amount", key: "cod_amount", width: 15 },
            //   { header: "Product category", key: "product_category", width: 15 },
            //   { header: "Product Name", key: "product_name", width: 20 },
            //   { header: "Specification type", key: "specification_type", width: 20 },
            //   { header: "Specification detail", key: "specification_detail", width: 20 },
            //   { header: "color", key: "color", width: 20 },
            //   { header: "QTY", key: "qty", width: 20 },
            //   { header: "Unit", key: "unit", width: 20 },
            //   { header: "Declare Value for COD", key: "declare_value_for_cod", width: 20 },
            //   { header: "Parcel Type", key: "parcel_type", width: 10 },
            //   { header: "Width (cm)", key: "width_cm", width: 10 },
            //   { header: "Length (cm)", key: "length_cm", width: 10 },
            //   { header: "Height (cm)", key: "height_cm", width: 10 },
            //   { header: "Weight (kg)", key: "weight", width: 20 },
            //   { header: "Total Box", key: "total_box", width: 10 },
            //   { header: "Sat Del", key: "sat_del", width: 10 },
            //   { header: "HCR", key: "hcr", width: 10 },
            //   { header: "Return POD", key: "return_pod", width: 10 },
            //   { header: "Service Code", key: "service_code", width: 10 },
            //   { header: "Consignment Type", key: "consignment_type", width: 20 },
            //   { header: "Return POD Remark", key: "return_pod_remark", width: 20 },
            //   { header: "Return POD Amount", key: "return_pod_amount", width: 20 },
            //   { header: "Special Remark", key: "special_remark", width: 20 },
            //   { header: "Extended Label", key: "extended_label", width: 20 },
            // ]

            //  kex version
            worksheet.columns = [
              { header: "Consignment No. (หมายเลขพัสดุ หากไม่กรอก ระบบจะสร้างให้อัตโนมัติ)", key: "consignment_no", width: 15 },
              { header: "Customer Ref No.\n(เลขอ้างอิง)", key: "customer_ref_no", width: 15 },
              { header: "Sender Code\n(รหัสผู้ส่ง)", key: "sender_code", width: 10 },
              { header: "Recipient Code\n(รหัสผู้รับ)", key: "recipient_code", width: 10 },
              { header: "Recipient Name\n(ชื่อผู้รับ)", key: "recipient_name", width: 30 },
              { header: "Recipient Phone\n(เบอร์มือถือผู้รับ)", key: "recipient_phone", width: 30 },
              { header: "Recipient  Postcode\n(รหัสไปรษณีย์ผู้รับ)", key: "recipient_postcode", width: 10 },
              { header: "Recipient Address\n(ที่อยู่)", key: "recipient_address", width: 70 },
              { header: "Recipient Contact Person\n(ชื่อผู้ติดต่อ)", key: "recipient_contact_person", width: 20 },
              { header: "Recipient Contact Person Phone\n(เบอร์โทรศัพท์ผู้ติดต่อ)", key: "recipient_contact_person_phone", width: 15 },
              { header: "Service Code\n(รหัสบริการ ค่าเริ่มต้น ND , และสามารถเลือก 2D  หรือ 3D )", key: "service_code", width: 10 },
              { header: "Total Weight\n(น้ำหนักรวมทั้งหมด ค่าเริ่มต้นเป็น 1 KG)", key: "total_weight", width: 20 },
              { header: "Length\n(ความยาว CM)", key: "length", width: 10 },
              { header: "Width\n(ความกว้าง CM)", key: "width", width: 10 },
              { header: "Height\n(ความสูง CM)", key: "height", width: 10 },
              { header: "Total Box\n(จำนวนกล่อง)", key: "total_box", width: 10 },
              { header: "Special Remark\n(หมายเหตุ)", key: "special_remark", width: 10 },
              { header: "Extended Label\n(ข้อมูลในส่วนนี้จะถูกนำไปแสดงเป็น Label หน้าที่สอง เมื่อท่านกด Print Label เท่านั้น)\n*กรอกข้อมูลได้ไม่เกิน 450 รวมตัวอักษร ช่องว่าง และอักขระ หากท่านใส่ลูกน้ำ ( , ) ระบบจะทำการใส่เลขลำดับให้ท่านโดยอัตโนมัติ)", key: "extended_label", width: 20 },
              { header: "Declare Value\n(มูลค่าสินค้า THB)", key: "declare_value", width: 10 },
              { header: "Consignment Type\n(ประเภทพัสดุ normal/exchange)", key: "consignment_type", width: 20 },
              { header: "COD Amount\n(ยอดเก็บเงินปลายทาง THB)", key: "cod_amount", width: 15 },
              { header: "Declare Value for COD\n(ราคาสินค้า)", key: "declare_value_for_cod", width: 20 },
              { header: "Product Category\n（ประเภทสินค้า）", key: "product_category", width: 15 },
              { header: "Product Name\n（ชื่อสินค้า）", key: "product_name", width: 20 },
              { header: "Product Color\n(สี)", key: "product_color", width: 20 },
              { header: "Product Specification type\n(ประเภทรูปแบบสินค้า)", key: "product_specification_type", width: 20 },
              { header: "Product Specification\n(รายละเอียดรูปแบบ)", key: "product_specification", width: 20 },
              { header: "Product Quantity Type\n(ประเภทหน่วย)", key: "product_quantity_type", width: 20 },
              { header: "Product Quantity\n(จำนวน)", key: "product_quantity", width: 20 },
              { header: "Return POD\n(เอกสารนำกลับ N = None / Y = Signature / S = Seal / B = Signature & Seal)", key: "return_pod", width: 10 },
              { header: "Return POD Remark\n(หมายเหตุ หรือคำอธิบาย สำหรับเอกสารนำกลับ)", key: "return_pod_remark", width: 20 },
              { header: "Return POD Amount\n(จำนวนเอกสารที่ต้องนำกลับ)", key: "return_pod_amount", width: 20 },
            ]

            let ids_exported = []
            _.forEach(mData, r => {
              if (typeof r != "undefined") {
                ids_exported.push(r.orderid)
                worksheet.addRow(r)
              }
            })

            try {
              let dname = this.$moment().format("DDMMYYYY-HHmmss")
              let filename =
                "KERRY-ORDER-" +
                ids_exported.length +
                "-" +
                dname.toString() +
                ".xlsx"
              let databuffer = await workbook.xlsx.writeBuffer()
              saveAs(new Blob([databuffer]), filename)

              // update order status to ka-exported
              let formData2 = new FormData()
              formData2.append("items", JSON.stringify(ids_exported))
              formData2.append("status", ORDERSTATUS.KERRYEXPORTED)

              await api.updateBulkOrderStatus(formData2)
              this.dialogloading = false
              this.loadData()
            } catch (error) {
              console.log("export excel error:", error.message)
              this.dialogloading = false // hide dialog loading
            }
            orders = null
            mData = null
            workbook = null
            result = null
          } else {
            // no data
          }
          ids = null
        } else {
          alert("กรุณาเลือก order ที่ต้องการ export!")
        }
        itemsSelected = null
        this.selected = []
      }
    },
    doActionMenu(actionType) {
      this.selectedStatusAction = actionType
      this.confirmChangeStatusDlg = true
    },
    doChangeStatus() {
      this.changeOrderStatus()
      this.confirmChangeStatusDlg = false
    },
    async doScan() {
      if (this.selected.length > 0) {
        if (confirm("คุณต้องการเพิ่มข้อมูลไปรายการ SCAN ใช่หรือไม่?")) {
          let items = this.selected
          let itemsScan = _.map(items, item => {
            return {
              ref_order_id: item.id,
              qty: 1,
              status: SCANSTATUS.PENDING
            }
          })

          let formData = new FormData()
          formData.append("items", JSON.stringify(itemsScan))
          let result = await api.insertToOrderScan(formData)
          if (result) {
            // update success
            this.snackbar = {
              active: true,
              msg: `เพิ่มข้อมูลรายการ SCAN สำเร็จ`
            }

            this.selected = []
          } else {
            // update failed
            this.snackbarFaild = {
              active: true,
              msg: `เพิ่มข้อมูล SCAN ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
            }
          }
        }
      }
    },
    async changeOrderStatus() {
      if (this.selected.length > 0) {
        let itemsSelected = this.selected
        let ids = _.map(itemsSelected, item => {
          return item.id
        })

        let formData = new FormData()
        formData.append("items", JSON.stringify(ids))
        formData.append("status", this.selectedStatusAction.actionType)
        let result = await api.updateBulkOrderStatus(formData)
        if (result) {
          // update success
          this.snackbar = {
            active: true,
            msg: `อัพเดตสถานะสำเร็จ`
          }

          this.selected = []
          this.loadData()

          if (this.isadmin) {
            this.$refs.totalCard.loadData()
            this.$refs.codCard.loadData()
            this.$refs.tranferCard.loadData()
          }
        } else {
          // update failed
          this.snackbarFaild = {
            active: true,
            msg: `อัพเดตสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          }
        }
      }
    },
    mySelected() {
      //console.log("total items", this.selected)
    },
    resetSearch: _.debounce(function() {
      this.filterBranchId = ""
      this.filterStatus = ""
      this.filterTransporter = ""
      this.selected = []
      if (this.search == "") {
        this.loadData()
      } else {
        this.search = ""
      }
    }, 700),
    doSearch: _.debounce(function() {
      this.selected = []
      if (this.options.page == 1) {
        this.loadData()
      } else {
        this.options.page = 1
      }
    }, 700),
    searchItems: _.debounce(function() {
      // _.debounce ใน lodash ต่างจาก settimeout อย่างไร
      // 1. debounce จะ return ค่าออกมาเป็น function  สว่น   settimeout จะ return เป็น id
      // 2. debounce ตัวอย่างการใช้เช่น เวลาพิมพ์ search ข้อมูล function ใน debounce จะทำงานก็ต่อเมื่อเราได้หยุดพิมพ์ในเวลา 1.5 วิ แต่ถ้าในเวลา 1.5 วิ มีการพิมพ์ต่อ debounce ก็จะนับถอยหลังใหม่
      //     ซึ่งจะทำให้ การยิง request ไปที่ server ลดจำนวนการยิงลงจนกว่าเราจะพิมพ์ข้อความที่ต้องการค้นหาเสร็จ และไม่มีการพิมพ์ต่อเนื่องใน 1.5 วินั้นเอง
      if (this.options.page == 1) {
        this.loadData()
      } else {
        this.options.page = 1
      }
    }, 2500),
    loadData() {
      this.getDataFromApi()
        .then(data => {
          this.mDataArray = data.rows
          this.totalItems = data.count
          data = null
        })
        .catch(error => {
          this.mDataArray = []
          // token is expired
          this.$router.push("/logout")
          console.log("myerror :", error)
        })
    },
    getDataFromApi() {
      this.dialogloading = true
      return new Promise((resolve, reject) => {
        const { page, itemsPerPage } = this.options
        let branchid = this.filterBranchId || "none"
        let filter_status = this.filterStatus || "none"
        let filter_transporter = this.filterTransporter || "none"
        api
          .getOrdersWaiting(
            page,
            itemsPerPage,
            this.search,
            branchid,
            filter_status,
            filter_transporter
          )
          .then(result => {
            if (typeof result.data.rows != "undefined") {
              let { rows, count } = result.data
              setTimeout(() => {
                this.dialogloading = false
                resolve({ rows, count })
                rows = null
                result = null
              }, 300)
            } else {
              this.dialogloading = false
              reject({ message: result.data.message })
              result = null
            }
          })
      })
    },
    editItem(item) {
      this.$router.push(`/billing-edit/${item.id}`)
    },
    deleteItem(item) {
      this.selectedItemId = item.id
      this.selectedItem = `รหัส: ${item.id} ${item.name}`
      this.confirmDeleteDlg = true
    },
    changeTransporter: _.debounce(async function(item) {
      this.dataTableLoading = true
      let { id, transporter } = item
      let formData = new FormData()
      formData.append("id", id)
      formData.append("transporter", transporter)
      let result = await api.setOrderStatus(formData)
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนขนส่งสำเร็จ`
        }
      } else {
        this.snackbarFaild = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนขนส่งไม่สำเร็จ`
        }
      }
      this.dataTableLoading = false
      if (this.isadmin) {
        this.$refs.totalCard.loadData()
      }

      this.loadData()
    }, 1000),
    async confirmDelete() {
      let result = await api.deleteOrder(this.selectedItemId)
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: "ลบข้อมูลสำเร็จ"
        }

        if (this.isadmin) {
          this.$refs.totalCard.loadData()
          this.$refs.codCard.loadData()
          this.$refs.tranferCard.loadData()
        }
      } else {
        this.snackbarFaild = {
          active: true,
          msg: "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง"
        }
      }

      this.confirmDeleteDlg = false // ซ้อนการแจ้งเตือน dialog
      this.loadData()
    },
    changePaymentMethod: _.debounce(async function(item) {
      this.dataTableLoading = true
      let { id, is_cod } = item
      let formData = new FormData()
      formData.append("id", id)
      formData.append("is_cod", is_cod)
      let result = await api.setOrderPaymentMethod(formData)
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินสำเร็จ`
        }
      } else {
        this.snackbarFaild = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินไม่สำเร็จ`
        }
      }

      this.dataTableLoading = false
      if (this.isadmin) {
        this.$refs.codCard.loadData()
        this.$refs.tranferCard.loadData()
      }

      this.loadData()
    }, 1000),
    selectedRow(val) {
      //console.log("select", val);
    }
  },
  computed: {
    isAdminLevel() {
      this.isadmin = this.isShow()
      return this.isadmin // from userLevel mixins
    }
  },
  watch: {
    // showWarningMsgNewOrder() {
    //   setTimeout(() => {
    //     this.showWarningMsgNewOrder.alert = false;
    //   }, 5000);
    // },
    options: {
      //เมื่อค่า options ที่เรา sync กับ datable เปลี่ยน method นี้จะทำงานทันที  watch นี้ ชื่อ method ต้องตรงกันกับตัวแปรที่ประกาศข้างบน
      handler(newValue, oldValue) {
        //console.log(this.options.page, this.options.itemsPerPage)
        if (newValue != oldValue) {
          this.loadData()
        }
      },
      deep: true
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.searchItems()
        }
      }
    },
    hasOrder: {
      handler(newValue, oldValue) {
        if (this.isadmin) {
          this.$refs.totalCard.loadData()
          this.$refs.codCard.loadData()
          this.$refs.tranferCard.loadData()
        }
        this.loadData()
      }
    }
  },
  beforeDestroy: function() {
    delete this.mDataArray
    delete this.selected
    delete this.options
    delete this.headers
  }
}
</script>

<style scoped>
.my-td-width {
  width: 120px;
}
.my-td-width2 {
  width: 170px;
}
.my-select-style {
  width: 120px;
  font-size: 14px;
  padding-top: 15px;
}
.my-select-style2 {
  width: 100px;
  font-size: 14px;
  padding-top: 15px;
}
</style>
